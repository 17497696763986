import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Media, Row } from "reactstrap";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import {
  formatPrice,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  GetUserIdForHeader,
  replaceLoclizationLabel,
  ScrollIntoSpecificDiv,
} from "../../../helpers/CommonHelper";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  convertDateToDifferentFormats,
  getFileExtensionFromContentType,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import rootAction from "../../../stateManagment/actions/rootAction";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import useMobileSize from "helpers/utils/isMobile";

const OrdersHistory = () => {
  const dispatch = useDispatch();
  const isTablet = useMobileSize(991);
  // const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TITLE"]);
  // const [adminPanelBaseURL, setadminPanelBaseURL] = useState(
  //   Config["ADMIN_BASE_URL"]
  // );
  const [OrderMasterList, setOrderMasterList] = useState([]);
  const [OrderItemsDetailList, setOrderItemsDetailList] = useState([]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  //-get login user from session
  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const [viewOrderDetail, setViewOrderDetail] = useState(false);
  const [orderNumberActive, setOrderNumberActive] = useState("");

  const onBack = () => {
    setViewOrderDetail(false);
  };
  const viewOrderItemsDetails = async (OrderId) => {
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));
    setViewOrderDetail(true);

    const headersDetail = {
      // customerid: userData?.UserID,
      // customeremail: userData.EmailAddress,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramDetail = {
      requestParameters: {
        OrderId: OrderId,
        recordValueJson: "[]",
      },
    };

    let responseDetailOrderDetail = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_CUSTOME_ORDER_HISTORY_DETAIL"],
      null,
      paramDetail,
      headersDetail,
      "POST",
      true
    );
    if (
      responseDetailOrderDetail !== null &&
      responseDetailOrderDetail.data !== null
    ) {
      await setOrderItemsDetailList(
        JSON.parse(responseDetailOrderDetail.data.data)
      );
      console.log(JSON.parse(responseDetailOrderDetail.data.data));
    }

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);

    try {
      ScrollIntoSpecificDiv("order_item_detail", "smooth");
    } catch (err) {
      console.log(err.message);
    }
  };
  const downloadDigitalProduct = async (OrderItemID, ProductName) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let UserID = await GetUserIdForHeader();
    let fileUrl =
      Config["ADMIN_BASE_URL"] +
      Config["COMMON_CONTROLLER_SUB_URL"] +
      Config.END_POINT_NAMES["DOWNLOAD_DIGITAL_FILE"];
    fileUrl = `${fileUrl}/${OrderItemID ?? 0}/${UserID}`;

    const response = await fetch(fileUrl, {
      headers: headers,
    });

    const contentType = await response.headers.get("content-type");
    const fileExtension = getFileExtensionFromContentType(contentType);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    let FileName = replaceWhiteSpacesWithDashSymbolInUrl(ProductName);
    FileName = FileName ?? "Your_Digital_Product";
    link.setAttribute("download", FileName + fileExtension);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    // declare the data fetching function
    const getOrderHistoryMaster = async () => {
      const headers = {
        // customerid: userData?.UserID,
        // customeremail: userData.EmailAddress,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          UserId: loginUser.UserID,
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CUSTOMER_ORDER_HISTORY_MASTER"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response !== null && response.data !== null) {
        await setOrderMasterList(JSON.parse(response.data.data));
        console.log(JSON.parse(response.data.data));
        viewOrderItemsDetails(JSON.parse(response.data.data)[0].OrderId);
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["OrdersHistory"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getOrderHistoryMaster().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);
  console.log(OrderItemsDetailList);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Orders History</title>
        <meta name="description" content={siteTitle + " Orders History"} />
        <meta name="keywords" content="Orders History"></meta>
      </Helmet>

      <div className="">
        <SiteBreadcrumb title="Order History" parent="Home" />

        <section className="cart-section order-history section-big-py-space">
          <div className="custom-container">
            {!isTablet ? (
              <Row>
                <Col
                  lg="3"
                  xxl="2.5"
                  className="side-left-product-detail order-history-list"
                >
                  {OrderMasterList?.map((item, idx) => (
                    <div
                      className={
                        item.OrderId === OrderItemsDetailList[0]?.OrderID
                          ? "order-history-item active"
                          : "order-history-item"
                      }
                      key={idx}
                      onClick={() => {
                        viewOrderItemsDetails(item.OrderId);
                      }}
                    >
                      <p className="order-no"> {item.OrderNumber} </p>
                      <div className="d-flex flex-row justify-content-between">
                        <span className="order-date">
                          {convertDateToDifferentFormats(
                            item.OrderDateUTC,
                            "dd-mm-yyyy"
                          )}
                        </span>
                        <span className="order-status">
                          {item.LatestStatusName}
                        </span>
                      </div>
                    </div>
                  ))}
                  {/* <table className="table cart-table table-responsive-xs">
                  <thead>
                    <tr className="table-head">
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Order Number",
                              "lbl_ordrhis_orderno"
                            )
                          : "Order Number"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Order Date",
                              "lbl_ordrhis_orderdte"
                            )
                          : "Order Date"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Status",
                              "lbl_ordrhis_orderstatus"
                            )
                          : "Status"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Total Items",
                              "lbl_ordrhis_totalitem"
                            )
                          : "Total Items"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Total",
                              "lbl_ordrhis_total"
                            )
                          : "Total"}
                      </th>

                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Detail",
                              "lbl_ordrhis_detail"
                            )
                          : "Detail"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderMasterList?.map((item, idx) => (
                      <tr>
                        <td>
                          <a href="#">
                            <span className="dark-data">
                              {item.OrderNumber}
                            </span>
                          </a>
                        </td>
                        <td>
                          <a href="#">
                            {convertDateToDifferentFormats(
                              item.OrderDateUTC,
                              "dd-mm-yyyy"
                            )}
                          </a>
                        </td>
                        <td>
                          <span className="dark-data">
                            {" "}
                            {item.LatestStatusName}
                          </span>
                        </td>
                        <td>
                          <span>{item.TotalItems}</span>
                        </td>
                        <td>
                          <span className="dark-data">
                            {" "}
                            {GetDefaultCurrencySymbol()}
                            {item.OrderTotal}
                          </span>
                        </td>
                        <td>
                          <Link
                            to="#"
                            className="order-history-view"
                            onClick={() => {
                              viewOrderItemsDetails(item.OrderId);
                            }}
                          >
                            <i className="fa fa-eye"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
                </Col>
                <Col
                  lg="9"
                  xxl="9.5"
                  xs="12"
                  className="ps-0 ps-md-3 side-right-product-detail"
                >
                  {OrderItemsDetailList !== undefined &&
                    OrderItemsDetailList !== null &&
                    OrderItemsDetailList.length > 0 && (
                      <table className="table cart-table table-responsive-xs">
                        <thead>
                          <tr className="table-head">
                            <th scope="col" id="lbl_ordrhis_prd">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Image",
                                    "lbl_ordrhis_prd"
                                  )
                                : "Image"}
                            </th>
                            <th scope="col" id="lbl_ordrhis_prdnme">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Free",
                                    "lbl_ordrhis_prdnme"
                                  )
                                : "Name"}
                            </th>

                            <th scope="col" id="lbl_ordrhis_price">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Unit Price",
                                    "lbl_ordrhis_price"
                                  )
                                : "Unit Price"}
                            </th>
                            <th scope="col" id="lbl_ordrhis_qty">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Quantity",
                                    "lbl_ordrhis_qty"
                                  )
                                : "Quantity"}
                            </th>
                            <th scope="col" id="lbl_ordrhis_itmtotal">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    " Total",
                                    "lbl_ordrhis_itmtotal"
                                  )
                                : " Total"}
                            </th>
                            {/* <th scope="col" id="lbl_ordrhis_isdigital">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Is Digital Product",
                                  "lbl_ordrhis_isdigital"
                                )
                              : "Is Digital Product"}
                          </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {OrderItemsDetailList?.map((itemDetail, idx) => (
                            <tr key={idx}>
                              <td>
                                <Media
                                  src={itemDetail.DefaultImageUrl}
                                  alt="product"
                                  className="img-fluid"
                                />
                              </td>
                              <td>
                                <p>{itemDetail.ProductName}</p>
                              </td>
                              <td>
                                <p>{formatPrice(itemDetail.Price)}</p>
                              </td>
                              <td>
                                <h4>{itemDetail.Quantity}</h4>
                              </td>
                              <td>
                                <h4>
                                  {formatPrice(itemDetail.OrderItemTotal)}
                                </h4>
                              </td>
                              {/* <td>
                              <span>
                                {itemDetail?.IsDigitalProduct !== undefined &&
                                itemDetail.IsDigitalProduct == true &&
                                (itemDetail.LatestStatusID ==
                                  Config.ORDER_STATUS["Completed"] ||
                                  itemDetail.ShippingStatusID ==
                                    Config.ORDER_STATUS["Completed"]) ? (
                                  <Link
                                    to="#"
                                    className="digital-download-btn"
                                    onClick={() => {
                                      downloadDigitalProduct(
                                        itemDetail.OrderItemID,
                                        itemDetail.ProductName
                                      );
                                    }}
                                  >
                                    Download
                                  </Link>
                                ) : itemDetail?.IsDigitalProduct == true ? (
                                  "Yes (In Progress)"
                                ) : (
                                  "No"
                                )}
                              </span>
                            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  <div className="cart-buttons">
                    <div className="pull-right">
                      <Link
                        to={`/${getLanguageCodeFromSession()}/`}
                        className="btn btn-normal btn-sm"
                        id="lbl_ordrhis_continue_ship"
                      >
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Continue Shopping",
                              "lbl_ordrhis_continue_ship"
                            )
                          : "Continue Shopping"}
                      </Link>
                    </div>
                  </div>
                </Col>
                {/* <Col sm="12">
                <table className="table cart-table table-responsive-xs">
                  <thead>
                    <tr className="table-head">
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Order Number",
                              "lbl_ordrhis_orderno"
                            )
                          : "Order Number"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Order Date",
                              "lbl_ordrhis_orderdte"
                            )
                          : "Order Date"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Status",
                              "lbl_ordrhis_orderstatus"
                            )
                          : "Status"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Total Items",
                              "lbl_ordrhis_totalitem"
                            )
                          : "Total Items"}
                      </th>
                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Total",
                              "lbl_ordrhis_total"
                            )
                          : "Total"}
                      </th>

                      <th scope="col">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Detail",
                              "lbl_ordrhis_detail"
                            )
                          : "Detail"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderMasterList?.map((item, idx) => (
                      <tr>
                        <td>
                          <a href="#">
                            <span className="dark-data">
                              {item.OrderNumber}
                            </span>
                          </a>
                        </td>
                        <td>
                          <a href="#">
                            {convertDateToDifferentFormats(
                              item.OrderDateUTC,
                              "dd-mm-yyyy"
                            )}
                          </a>
                        </td>
                        <td>
                          <span className="dark-data">
                            {" "}
                            {item.LatestStatusName}
                          </span>
                        </td>
                        <td>
                          <span>{item.TotalItems}</span>
                        </td>
                        <td>
                          <span className="dark-data">
                            {" "}
                            {GetDefaultCurrencySymbol()}
                            {item.OrderTotal}
                          </span>
                        </td>
                        <td>
                          <Link
                            to="#"
                            className="order-history-view"
                            onClick={() => {
                              viewOrderItemsDetails(item.OrderId);
                            }}
                          >
                            <i className="fa fa-eye"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col> */}
              </Row>
            ) : (
              <Row>
                {!viewOrderDetail ? (
                  <Col
                    lg="3"
                    xxl="2.5"
                    className="side-left-product-detail order-history-list"
                  >
                    {OrderMasterList?.map((item, idx) => (
                      <div
                        className={
                          item.OrderId === OrderItemsDetailList[0]?.OrderID
                            ? "order-history-item active"
                            : "order-history-item"
                        }
                        key={idx}
                        onClick={() => {
                          viewOrderItemsDetails(item.OrderId);
                          setOrderNumberActive(item.OrderNumber);
                        }}
                      >
                        <p className="order-no"> {item.OrderNumber} </p>
                        <div className="d-flex flex-row justify-content-between">
                          <span className="order-date">
                            {convertDateToDifferentFormats(
                              item.OrderDateUTC,
                              "dd-mm-yyyy"
                            )}
                          </span>
                          <span className="order-status">
                            {item.LatestStatusName}
                          </span>
                        </div>
                      </div>
                    ))}
                  </Col>
                ) : (
                  <Col className="side-right-product-detail">
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <p className="fw-bold order-history-title">
                        <span> Order Details</span>
                        <span>{orderNumberActive}</span>
                      </p>
                      <button
                        onClick={onBack}
                        className="btn btn-normal btn-outline"
                      >
                        Back to Order
                      </button>
                    </div>

                    {OrderItemsDetailList !== undefined &&
                      OrderItemsDetailList !== null &&
                      OrderItemsDetailList.length > 0 && (
                        <table className="table cart-table table-responsive-xs">
                          <thead>
                            <tr className="table-head">
                              <th scope="col" id="lbl_ordrhis_prd">
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Image",
                                      "lbl_ordrhis_prd"
                                    )
                                  : "Image"}
                              </th>
                              <th scope="col" id="lbl_ordrhis_prdnme">
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Free",
                                      "lbl_ordrhis_prdnme"
                                    )
                                  : "Info"}
                              </th>

                              <th scope="col" id="lbl_ordrhis_itmtotal">
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      " Total",
                                      "lbl_ordrhis_itmtotal"
                                    )
                                  : " Total"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {OrderItemsDetailList?.map((itemDetail, idx) => (
                              <tr key={idx}>
                                <td>
                                  <Media
                                    src={itemDetail.DefaultImageUrl}
                                    alt="product"
                                    className="img-fluid"
                                  />
                                </td>
                                <td>
                                  <p>{itemDetail.ProductName}</p>
                                  <p>
                                    {formatPrice(itemDetail.Price)} x
                                    {itemDetail.Quantity}
                                  </p>
                                </td>
                                <td>
                                  <p className="fw-bold">
                                    {formatPrice(itemDetail.OrderItemTotal)}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                  </Col>
                )}
              </Row>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default OrdersHistory;
