import { Button, Modal, ModalBody } from "reactstrap";

const PayOs = ({ open = false, close, onOpenPayment }) => {
  return (
    <Modal
      isOpen={open}
      toggle={close}
      centered={true}
      size="lg"
      className="theme-modal"
      id="exampleModal"
      role="dialog"
      aria-hidden="true"
    >
      <ModalBody className="modal-content">
        <Button
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={close}
        >
          <span aria-hidden="true">×</span>
        </Button>
        <div className="news-latter">
          <div className="modal-bg">
            <div className="offer-content">
              <div>
                <h2>Pay OS Options!</h2>
                <button
                  className="btn-normal btn mt-3 rounded"
                  onClick={onOpenPayment}
                  style={{ width: "80%" }}
                >
                  Purchase
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PayOs;
