import { useSelector } from "react-redux";
import { MakeApiCallAsync } from "./ApiHelpers";
import Config from "./Config";
import { checkIfStringIsEmtpy } from "./ValidationHelper";

export const RedirectToWhatsAppPage = () => {
  window.open("https://wa.me/923128545494", "_blank");
};

export const GetDefaultCurrencySymbol = () => {
  let DefaultCurrencySymbol = "$"; //--USD is consider as default if there is no setting in appsetting.json file
  DefaultCurrencySymbol = Config.APP_SETTING["DefaultCurrencySymbol"] ?? "$";
  return DefaultCurrencySymbol;
};

export const GetDefaultCurrencyCode = () => {
  // const loginUserDataJson = useSelector((state) => state.userReducer.user);
  // const loginUser = JSON.parse(loginUserDataJson ?? "{}");
  let DefaultCurrencyCode = "USD"; //--USD is consider as default if there is no setting in appsetting.json file

  try {
    DefaultCurrencyCode = localStorage.getItem("currencyCode");
  } catch (err) {
    console.error(err.message);
  }
  if (!checkIfStringIsEmtpy(DefaultCurrencyCode)) {
    return "USD";
  } else {
    return DefaultCurrencyCode;
  }
  // if (
  //   loginUser === undefined ||
  //   loginUser.UserID === undefined ||
  //   loginUser.UserID < 1
  // ) {
  //   DefaultCurrencyCode = localStorage.getItem("currencyCode") ?? "USD";
  // } else {
  //   DefaultCurrencyCode = loginUser.CountryName.toUpperCase().includes(
  //     "VIETNAM"
  //   )
  //     ? "VND"
  //     : "USD";
  // }
  // return DefaultCurrencyCode;
};

export const IsLogin = () => {
  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");
  if (
    loginUser === undefined ||
    loginUser.UserID === undefined ||
    loginUser.UserID < 1
  ) {
    return false;
  } else {
    return true;
  }
};

export const GetTokenForHeader = async () => {
  try {
    let Token = "";

    let tokenFromStorage = localStorage.getItem("Token");

    if (
      tokenFromStorage !== null &&
      tokenFromStorage !== undefined &&
      tokenFromStorage !== ""
    ) {
      Token = tokenFromStorage;
    }
    return Token;
  } catch (err) {
    console.error(err.message);
    return "";
  }
};

export const GetUserIdForHeader = async () => {
  try {
    let UserID = "";

    let loginUserDataJson = localStorage.getItem("user");
    const loginUser = JSON.parse(loginUserDataJson ?? "{}");

    if (loginUser !== null && loginUser !== undefined && loginUser !== "") {
      UserID = loginUser.UserID;
    }
    return UserID;
  } catch (err) {
    console.error(err.message);
    return "";
  }
};

export const setCurrencyCodeInSession = async (CurrencyCode) => {
  try {
    let currency = CurrencyCode ?? "en";
    localStorage.setItem("currencyCode", currency);
  } catch (err) {
    console.error(err.message);
  }
};

export const setLanguageCodeInSession = async (LangCode) => {
  try {
    let lCode = LangCode ?? "en";
    localStorage.setItem("langCode", lCode);
  } catch (err) {
    console.error(err.message);
  }
};

export const getLanguageCodeFromSession = () => {
  let langCode = "en";

  try {
    langCode = localStorage.getItem("langCode");
  } catch (err) {
    console.error(err.message);
  }
  if (!checkIfStringIsEmtpy(langCode)) {
    return "en";
  } else {
    return langCode;
  }
};

export const GetLocalizationControlsJsonDataForScreen = async (
  entityId,
  htmlElementId = null
) => {
  let responseArray = [];

  try {
    let languageCode = getLanguageCodeFromSession();

    if (
      languageCode == undefined ||
      languageCode == null ||
      languageCode == ""
    ) {
      return responseArray;
    }

    if (languageCode == "en") {
      //-- do not perform localization for english
      return responseArray;
    }

    if (
      entityId == undefined ||
      entityId == null ||
      entityId == "" ||
      entityId < 1
    ) {
      return responseArray;
    }

    const headerLocalization = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramLocalization = {
      requestParameters: {
        entityId: entityId,
        languageCode: languageCode,
        recordValueJson: "[]",
      },
    };

    const localizationResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_LOCALIZATION_CSTM_PORTAL"],
      Config["COMMON_CONTROLLER_SUB_URL"],
      paramLocalization,
      headerLocalization,
      "POST",
      true
    );
    if (localizationResponse !== null && localizationResponse.data !== null) {
      let finalData = JSON.parse(localizationResponse.data.data);
      responseArray = finalData.labelsJsonData;
    }
  } catch (error) {
    console.log(error.message);
  }
  return responseArray;
};

export const replaceLoclizationLabel = (
  labelsJsonData,
  defaultTxt,
  labelHtmlId
) => {
  let labelTxt = "";
  try {
    if (
      labelsJsonData == null ||
      labelsJsonData == undefined ||
      labelsJsonData.length == 0
    ) {
      return defaultTxt;
    }

    let foundObj = labelsJsonData.find(
      (elem) => elem.labelHtmlId === labelHtmlId
    );
    labelTxt =
      !checkIfStringIsEmtpy(foundObj?.text) === true
        ? defaultTxt
        : foundObj?.text;
  } catch (err) {
    console.error(err.message);
    labelTxt = defaultTxt;
  }

  return labelTxt;

  // try {
  //     if (htmlElementId == null || htmlElementId == undefined) { //-- if htmlElementId is null, then its mean to run for whole body
  //         for (let itm = 0; itm <= labelsJsonData.length - 1; itm ++) {
  //             let htmlElement = document.getElementById(labelsJsonData[itm].labelHtmlId);
  //             if (htmlElement !== null && htmlElement !== undefined && checkIfStringIsEmtpy(labelsJsonData[itm].text)) {
  //                 let labelHtmlId = labelsJsonData[itm].labelHtmlId;
  //                 document.getElementById(labelHtmlId).innerHTML = labelsJsonData[itm].text;
  //             }
  //         }
  //     }
  //     else {//--if htmlElementId param is not null then only check localization for this specific html tag

  //         let parentElement = document.getElementById(htmlElementId);

  //         for (let itm = 0; itm <= labelsJsonData.length - 1; itm++) {
  //             let htmlElement = document.getElementById(labelsJsonData[itm].labelHtmlId);
  //             if (htmlElement !== null && htmlElement !== undefined && checkIfStringIsEmtpy(labelsJsonData[itm].text) && parentElement.contains(htmlElement)) {
  //                 let labelHtmlId = labelsJsonData[itm].labelHtmlId;
  //                 document.getElementById(labelHtmlId).innerHTML = labelsJsonData[itm].text;
  //             }
  //         }
  //     }
  // }
  // catch (err) {
  //     console.error(err.message);

  // }
};

export const ScrollIntoSpecificDiv = (divId, behaviorParam) => {
  try {
    document.getElementById(divId).scrollIntoView({
      behavior: behaviorParam ?? "smooth",
      // block: 'center',
      // inline: 'start'
    });
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const formatPrice = (str, currencyCode = "USD", length = 2) => {
  if (currencyCode === "VND") {
    length = 0;
  }
  let deleteText = Number(str)
    .toFixed(8)
    .replace(/\.?0+$/, "");
  deleteText = deleteText !== "NaN" ? deleteText : 0;
  const x = deleteText.toString().split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, length) : "";
  if (!x1) x1 = "0";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  if (currencyCode === "VND") {
    return result + " ₫";
  }
  return "$" + result;
};

export const isAppleBrowser = () => {
  const Apple = window.navigator.vendor;
  return Apple.includes("Apple");
};
