import Slider from "react-slick";
import { Col, Container, Media, Row } from "reactstrap";
import myImage1 from "../../../resources/themeContent/images/testimonial/1.jpg";
import myImage2 from "../../../resources/themeContent/images/testimonial/2.jpg";
import myImage3 from "../../../resources/themeContent/images/testimonial/3.jpg";

var settings = {
  autoplay: false,
  autoplaySpeed: 2500,
};

const Review = [
  {
    img: "../../../resources/themeContent/images/testimonial/1.jpg",
    user: "Emily",
    review:
      "Extremely happy with my purchase! Great quality item, just as I hoped, as it was a gift. Prompt replies from the shop as well as great communication. Definitely recommend!",
  },
  {
    img: "../../../resources/themeContent/images/testimonial/2.jpg",
    user: "James",
    review:
      "Exactly as described. Fast delivery. Very pleased with this item. Thank you!.",
  },
  {
    img: "../../../resources/themeContent/images/testimonial/3.jpg",
    user: "Oliver",
    review:
      "This item was everything I wanted it to be. Quality shipping and the surprises in the package were amazing",
  },
];
const CustomerTestimonial = () => {
  return (
    <>
      <section className="testimonial testimonial-inverse mt-4">
        <Container>
          <Row>
            <Col md="12">
              <div className="slide-1 no-arrow">
                <Slider {...settings}>
                  {Review.map((data, i) => (
                    <div key={i}>
                      <div className="testimonial-contain">
                        <div className="media">
                          <div className="testimonial-img">
                            <Media
                              src={
                                i === 0
                                  ? myImage1
                                  : i === 1
                                  ? myImage2
                                  : myImage3
                              }
                              className="img-fluid rounded-circle"
                              alt="testimonial"
                            />
                          </div>
                          <div className="media-body">
                            <h5>{data.user}</h5>
                            <p>{data.review}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CustomerTestimonial;
