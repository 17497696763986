import { Image } from "antd";
import "photoswipe/style.css";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Col, Media, Row } from "reactstrap";
import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";

const ProductDetailImages = (props) => {
  const slider1 = useRef(Slider);
  const slider2 = useRef(Slider);
  const [state, setState] = useState({ nav1: null, nav2: null });
  const [imagesList, setImagesList] = useState([]);

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);
  const { nav1, nav2 } = state;

  // useEffect(() => {
  //   let lightbox = new PhotoSwipeLightbox({
  //     gallery: '#my-gallery',
  //     children: 'a',

  //     showHideAnimationType: 'zoom',
  //     // showHideAnimationType: 'none',
  //     // zoomAnimationDuration: false,
  //     pswpModule: () => import('photoswipe')
  //   });
  //   lightbox.init();

  //   return () => {
  //     lightbox.destroy();
  //     lightbox = null;
  //   };
  // }, [])

  const MakeImageList = () => {
    let arrayData = [];
    if (
      props.ProductImages !== undefined &&
      props.ProductImages !== null &&
      props.ProductImages.length > 0
    ) {
      for (let i = 0; i < props.ProductImages.length; i++) {
        arrayData.push({
          __typename: getFileExtensionNameFromPath(),
          alt: "product detail image " + i,
          src: props.ProductImages[i].AttachmentURL,
        });
      }
    }

    setImagesList(arrayData);
  };

  useEffect(() => {
    MakeImageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ProductImages]);

  return (
    <div id="my-gallery">
      <Image.PreviewGroup>
        <Slider
          className="product-slick"
          asNavFor={nav2}
          ref={(slider) => (slider1.current = slider)}
        >
          {imagesList &&
            imagesList.map((img, i) => {
              return (
                <div key={i}>
                  <Image
                    src={img.src}
                    alt=""
                    className="img-fluid image_zoom_cls-0"
                  />
                </div>
              );
            })}
        </Slider>
      </Image.PreviewGroup>
      {/* <Slider className="product-slick" asNavFor={nav2} ref={(slider) => (slider1.current = slider)}>
        {imagesList &&
          imagesList.map((img, i) => {
            return (
              <a key={i} href={img.src} target="_blank" rel="noreferrer">
                <Media src={img.src} alt="" className="img-fluid image_zoom_cls-0" />
              </a>
            );
          })}
      </Slider> */}
      <Row>
        <Col>
          <Slider
            className="slider-nav"
            asNavFor={nav1}
            ref={(slider) => (slider2.current = slider)}
            slidesToShow={5}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
            adaptiveHeight={true}
          >
            {imagesList &&
              imagesList.map((img, i) => {
                return (
                  <div key={i}>
                    <Media
                      src={img.src}
                      alt=""
                      className="img-fluid  image_zoom_cls-0"
                    />
                  </div>
                );
              })}
          </Slider>
        </Col>
      </Row>
    </div>
  );
};
export default ProductDetailImages;
