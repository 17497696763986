import About from "web/views/about/About";
import Campaign from "web/views/campaign/Campaign";
import Cart from "web/views/checkout/Cart";
import Checkout from "web/views/checkout/Checkout";
import BrainTreeUrl from "web/views/checkout/components/paybraintree/BrainTreeUrl";
import RedirectUrl from "web/views/checkout/components/payos/RedirectUrl";
import VerifyCreateOrder from "web/views/checkout/components/VerifyCreateOrder";
import Refresh from "web/views/common/Refresh";
import CompareList from "web/views/compare/Index";
import ContactUs from "web/views/contactUs/Index";
import FaqPage from "web/views/faq/FaqPage";
import Home from "web/views/home/Index";
import Login from "web/views/login/Login";
import ResetPassword from "web/views/login/ResetPassword";
import AllProducts from "web/views/products/AllProducts";
import ProductDetail from "web/views/products/ProductDetail";
import BecomeVendor from "web/views/signup/BecomeVendor";
import OrdersHistory from "web/views/signup/OrdersHistory";
import Signup from "web/views/signup/Signup";
import UpdateProfile from "web/views/signup/UpdateProfile";

export const UserRoutes = [
  {
    path: "/all-products/:category_id/:category_name",
    element: <AllProducts />,
  },
  {
    path: "/product-detail/:product_id/:category/:product_name",
    element: <ProductDetail />,
  },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/", element: <Home /> },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/about", element: <About /> },
  { path: "/faq", element: <FaqPage /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/checkout", element: <Checkout /> },
  { path: "/braintree/new", element: <BrainTreeUrl /> },
  { path: "/payos/redirect-url", element: <RedirectUrl /> },
  { path: "/create-order/verify", element: <VerifyCreateOrder /> },
  { path: "/cart", element: <Cart /> },
  { path: "/update-profile", element: <UpdateProfile /> },
  {
    path: "/campaign/:campaign_id/:campaign_main_title",

    element: <Campaign />,
  },
  { path: "/orders-history", element: <OrdersHistory /> },
  { path: "/refresh", element: <Refresh /> },
  { path: "/become-seller", element: <BecomeVendor /> },
  { path: "/compare", element: <CompareList /> },
  { path: "/:langCode/", element: <Home /> },
  {
    path: "/:langCode/all-products/:category_id/:category_name",
    element: <AllProducts />,
  },
  {
    path: "/:langCode/product-detail/:product_id/:category/:product_name",

    element: <ProductDetail />,
  },
  { path: "/:langCode/contact-us", element: <ContactUs /> },
  { path: "/:langCode/login", element: <Login /> },
  { path: "/:langCode/signup", element: <Signup /> },
  { path: "/:langCode/reset-password", element: <ResetPassword /> },
  { path: "/:langCode/checkout", element: <Checkout /> },
  { path: "/:langCode/about", element: <About /> },
  { path: "/:langCode/faq", element: <FaqPage /> },
  { path: "/:langCode/cart", element: <Cart /> },
  { path: "/:langCode/update-profile", element: <UpdateProfile /> },
  {
    path: "/:langCode/campaign/:campaign_id/:campaign_main_title",

    element: <Campaign />,
  },
  { path: "/:langCode/orders-history", element: <OrdersHistory /> },
  { path: "/:langCode/refresh", element: <Refresh /> },
  { path: "/:langCode/become-seller", element: <BecomeVendor /> },
  { path: "/:langCode/compare", element: <CompareList /> },
];
