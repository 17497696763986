import { useRoutes } from "react-router-dom";
import MainLayout from "web/components/layout/MainLayout";
import { UserRoutes } from "./userRoutes";

const Router = () => {
  const routes = [
    {
      path: "/",
      element: <MainLayout />,
      children: UserRoutes,
    },
  ];
  return useRoutes(routes);
};

export default Router;
